import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { useOs } from '@wpp-open/react'
import { Suspense, lazy } from 'react'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'

import 'app/global.module.scss'
import ErrorBoundary from 'components/common/errorBoundary/ErrorBoundary'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { AppPermissions } from 'types/permissions/permissions'
import { routesManager } from 'utils/routesManager'

// Lazy-loaded components with explicit named exports handling
const NotFound = lazy(() => import('pages/404/NotFound').then(module => ({ default: module.NotFound })))
const MiroAuthCallback = lazy(() =>
  import('pages/components/projectModal/components/integrations/miro/MiroAuthCallback').then(module => ({
    default: module.MiroAuthCallback,
  })),
)
const TasksDashboardPage = lazy(() =>
  import('pages/dashboard/components/tasksDashboard/TasksDashboard').then(module => ({
    default: module.TasksDashboardPage,
  })),
)
const DashboardPage = lazy(() =>
  import('pages/dashboard/Dashboard').then(module => ({ default: module.DashboardPage })),
)
const CanvasTab = lazy(() =>
  import('pages/project/components/canvas/CanvasTab').then(module => ({ default: module.CanvasTab })),
)
const Files = lazy(() => import('pages/project/components/files/Files').then(module => ({ default: module.Files })))
const MembersTab = lazy(() =>
  import('pages/project/components/members/Members').then(module => ({ default: module.MembersTab })),
)
const Pinboard = lazy(() =>
  import('pages/project/components/pinboard/Pinboard').then(module => ({ default: module.Pinboard })),
)
const ProjectDashboard = lazy(() =>
  import('pages/project/components/projectDashboard/ProjectDashboard').then(module => ({
    default: module.ProjectDashboard,
  })),
)
const Tasks = lazy(() => import('pages/project/components/tasks/Tasks').then(module => ({ default: module.Tasks })))
const Timeline = lazy(() =>
  import('pages/project/components/timeline/Timeline').then(module => ({ default: module.Timeline })),
)
const Workflow = lazy(() =>
  import('pages/project/components/workflow/Workflow').then(module => ({ default: module.Workflow })),
)
const Project = lazy(() => import('pages/project/Project').then(module => ({ default: module.Project })))
const ProjectPage = lazy(() => import('pages/project/ProjectPage').then(module => ({ default: module.ProjectPage })))
const ProjectDemo = lazy(() =>
  import('pages/projectDemo/ProjectDemo').then(module => ({ default: module.ProjectDemo })),
)
const Settings = lazy(() => import('pages/settings/Settings').then(module => ({ default: module.Settings })))
const CanvasTemplate = lazy(() =>
  import('pages/template/CanvasTemplate').then(module => ({ default: module.CanvasTemplate })),
)
const TemplateTasks = lazy(() =>
  import('pages/template/tasks/TemplateTasks').then(module => ({ default: module.TemplateTasks })),
)
const Template = lazy(() => import('pages/template/Template').then(module => ({ default: module.Template })))
const TemplatePage = lazy(() =>
  import('pages/template/TemplatePage').then(module => ({ default: module.TemplatePage })),
)
const Templates = lazy(() => import('pages/templates/Templates').then(module => ({ default: module.Templates })))

const PermittedAccess = ({ permission }: { permission: AppPermissions | AppPermissions[] }) => {
  const { isPermitted } = useIsPermitted()
  return isPermitted(permission) ? <Outlet /> : <Navigate replace to={routesManager['404'].root.pattern} />
}

export function App() {
  const { osContext } = useOs()

  // @TODO: Remove after fix WPPLONOP-12848
  if (!osContext.baseUrl) return null

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <NiceModalProvider>
        <ErrorBoundary>
          <Suspense fallback={null}>
            <Routes>
              <Route index element={<Navigate to={routesManager.projects.root.pattern} />} />

              <Route path={`${routesManager.projects.root.pattern}/*`} element={<DashboardPage />} />
              <Route path={routesManager.miroAuthCallback.pattern} element={<MiroAuthCallback />} />

              <Route path={routesManager.project.root.pattern} element={<ProjectPage />}>
                <Route path={routesManager.project.demo.shortPattern} element={<ProjectDemo />} />

                <Route path="" element={<Project />}>
                  <Route path={routesManager.project.overview.shortPattern} element={<ProjectDashboard />} />
                  <Route path={routesManager.project.workflow.shortPattern} element={<Workflow />} />
                  <Route path={routesManager.project.canvas.shortPattern} element={<CanvasTab />} />
                  <Route path={routesManager.project.members.shortPattern} element={<MembersTab />} />
                  <Route path={routesManager.project.files.shortPattern} element={<Files />} />
                  <Route path={routesManager.project.tasks.shortPattern} element={<Tasks />} />
                  <Route path={routesManager.project.timeline.shortPattern} element={<Timeline />} />
                  <Route path={routesManager.project.pinboard.shortPattern} element={<Pinboard />} />
                </Route>
              </Route>

              <Route path={routesManager.template.root.pattern} element={<TemplatePage />}>
                <Route element={<Template />}>
                  <Route index element={<Navigate to={routesManager.project.canvas.shortPattern} replace />} />

                  <Route path={routesManager.project.canvas.shortPattern} element={<CanvasTemplate />} />

                  <Route path={routesManager.project.tasks.shortPattern} element={<TemplateTasks />} />
                </Route>
              </Route>

              <Route
                path={routesManager.templates.root.pattern}
                element={
                  <PermittedAccess
                    permission={[
                      AppPermissions.ORCHESTRATION_GLOBAL_MANAGE,
                      AppPermissions.ORCHESTRATION_PROJECTS_CREATE,
                      AppPermissions.ORCHESTRATION_WORKFLOW_TEMPLATE_VIEW,
                    ]}
                  />
                }
              >
                <Route index element={<Templates />} />
              </Route>

              <Route path={routesManager.tasks.root.pattern} element={<TasksDashboardPage />} />

              <Route
                path={`${routesManager.settings.root.pattern}/*`}
                element={
                  <PermittedAccess
                    permission={[
                      AppPermissions.ORCHESTRATION_GLOBAL_MANAGE,
                      AppPermissions.ORCHESTRATION_WORKSPACE_ATTRIBUTE_MANAGE,
                    ]}
                  />
                }
              >
                <Route path="*" element={<Settings />} />
              </Route>

              <Route path={routesManager['404'].root.pattern} element={<NotFound />} />

              <Route path="*" element={<Navigate replace to={routesManager.projects.root.pattern} />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </NiceModalProvider>
    </BrowserRouter>
  )
}
